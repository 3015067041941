import React from 'react'
import { Link } from 'gatsby'
import { Container } from 'react-bootstrap'
import Layout from '../components/layout'

const NotFoundPage = () => {
  return (
    <Layout title="Страница не найдена: 404" description="Запрошенная страница не найдена." brcr={['Страница не найдена']}>
      <section style={{ paddingTop: '187px', paddingBottom: '100px', backgroundColor: '#f7f7f7', minHeight: '70vh' }}>
        <Container>
          <p className="h1 font-weight-bolder">Ошибка 404</p>
          <p className="h3 mb-4">Страница не найдена</p>
          <Link href="/" className="h3">
            На главную
          </Link>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage
